import React from 'react';
import Content from '../component/Content/Content';
import Banner from '../component/Content/Banner';
import Integration from '../component/Integration/Integration';
import KeyStatistics from '../component/KeyStatistics/KeyStatistics';
import Services from '../component/Services/Services';
import Clients from '../component/Clients/Clients';
import Certifications from '../component/Certifications/Certifications';
import Testimonials from '../component/Testimonials/Testimonials';
import Footer from '../component/Footer/Footer';
import {
    // heroOne,
    products,
    integration,
    keyStatistics,
    clients,
    services,
    testimonialData,
    certificate,
    // AICTEData,
    contentCoverage,
    slider1,
    slider2,
    slider3
} from '../data/homeData';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function HomePage() {
    const items = [ <Content key={1} {...slider3} />, <Banner key={2} {...slider1} />, <Banner key={3} {...slider2} />];
    return (
        <>
            <Carousel
                fullHeightHover={false}
                indicators={false}
                swipe={true}
                navButtonsAlwaysVisible={true}
                autoPlay={false}
                NextIcon={ <ArrowForwardIosIcon />}
                PrevIcon={ <ArrowBackIosNewIcon /> }
                sx={{
                    height: '80vh', // Or adjust as needed to fit your content
                }}
                navButtonsProps={{ 
                    style: {
                        background: 'linear-gradient(to right,#024EFB,#2B86FC ,#71E5FF)',
                        opacity: 0.9
                    }
                }} 
            >
                {
                    items.map( (item, i) => <div key={i}>{item}</div> )
                }
            </Carousel>
            {/* <Content {...heroOne} />
            <Content {...AICTEData} /> */}
            <Content {...products} />
            <KeyStatistics {...contentCoverage} />
            <Integration {...integration} />
            <KeyStatistics {...keyStatistics} />            
            <Certifications {...certificate} />
            <Services {...services} />
            <Testimonials {...testimonialData} />
            <Clients {...clients} />
            <Footer />
        </>
    );
}

export default HomePage;
